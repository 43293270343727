import React, { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button.tsx';
import { useTheme } from '../contexts/ThemeContext.tsx';
import { useProducts } from '../contexts/ProductContext.tsx';
import { useCart } from '../contexts/CartContext.tsx';
import { setupTelegramMainButton } from '../utils/telegram.ts';
import Header from '../components/Header.tsx';
import { MinusIcon, PlusIcon } from "@radix-ui/react-icons";
import { styles } from '../styles/styles';
import { useApi } from '../api/hooks/useApi';
import { Product } from '../api/types';
// import { Product } from '../types.ts';

interface ProductDetailPageProps {
    productId: number;
    onBackClick: () => void;
    onCartClick: () => void;
}

const ProductDetailPage: React.FC<ProductDetailPageProps> = ({
                                                                 productId,
                                                                 onBackClick,
                                                                 onCartClick
                                                             }) => {
    const { isDark, themeParams } = useTheme();
    const { api } = useApi();
    const { getProductById } = useProducts();
    const { } = useCart();
    const [product, setProduct] = useState<Product | null>(null);
    const [quantity, setQuantity] = useState(1);
    const [_isAddingToCart, setIsAddingToCart] = useState(false);
    // const [selectedColor, setSelectedColor] = useState<string | null>(null);
    const [selectedColor] = useState<string | null>(null);

    console.log('ProductDetailPage: received productId:', productId);
    
    useEffect(() => {
        const loadProduct = async () => {
            if (productId) {
                const productData = await getProductById(productId);
                setProduct(productData);
            }
        };
        loadProduct();
    }, [productId]);

    useEffect(() => {
        if (!product) return;

        setupTelegramMainButton({
            text: 'Добавить в корзину',
            color: themeParams.button_color || '#3390EC',
            textColor: themeParams.button_text_color || '#FFFFFF',
            isVisible: false,
            isActive: false,
            onClick: () => handleAddToCart()
        });

        return () => {
            setupTelegramMainButton({
                isVisible: false
            });
        };
    }, [selectedColor, themeParams, product]);

    if (!productId || productId === 0) {
        return (
            <div className={`min-h-screen flex items-center justify-center ${
                isDark ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'
            }`}>
                <div className="text-center">
                    <p className="mb-4">Неверный ID продукта</p>
                    <Button onClick={onBackClick}>Вернуться назад</Button>
                </div>
            </div>
        );
    }

    if (!product) {
        return (
            <div className={`min-h-screen flex items-center justify-center ${
                isDark ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'
            }`}>
                <p>Product not found</p>
            </div>
        );
    }

    const incrementQuantity = (): void => {
        setQuantity(quantity + 1);
    };

    const decrementQuantity = (): void => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const handleAddToCart = async () => {
        if (!product || _isAddingToCart) return;
        
        setIsAddingToCart(true);
        try {
            await api.addToCart(product.id.toString(), quantity);
            onCartClick();
        } catch (error) {
            // Здесь можно добавить отображение ошибки пользователю
        } finally {
            setIsAddingToCart(false);
        }
    };

    return (
        <div className={`min-h-screen pb-16 ${isDark ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
            <Header
                title="Larez"
                showBackButton
                showCart
                onBackClick={onBackClick}
                onCartClick={onCartClick}
            />

            <div className={`${isDark ? 'bg-gray-800' : 'bg-white'} p-4 rounded-lg`}>
                <img
                    src={product.images[0]}
                    alt={product.name}
                    className="w-full object-cover rounded-lg h-[25rem]"
                />
            </div>

            <div className="px-4 py-3">
                <h1 className="text-xl font-semibold mb-1 leading-[28px] tracking-[-0.35px] text-[22px] font-inter">{product.name}</h1>
                <h2 className="text-xl font-semibold mb-4 text-[34px] font-inter leading-[100%] tracking-[-0.45px]">₽{product.price}</h2>

                <p className={`text-sm mb-6 ${isDark ? 'text-[#85858B]' : 'text-[#85858B]'} font-normal font-inter leading-[22px] tracking-[-0.41px] text-[17px]`}>
                    {product.description}
                </p>

                {product.details && (
                    <div className="mb-6">
                        <h3 className="font-semibold mb-2 text-[22px] font-inter leading-[28px] tracking-[-0.35px]">Детали</h3>
                        <div className={`text-sm divide-y ${
                            isDark ? 'divide-gray-700' : 'divide-gray-200'
                        }`}>
                            <div className={styles.details.row}>
                                <span className={styles.details.label(isDark)}>Артикул</span>
                                <span className={styles.details.value(isDark)}>{product.details.articleNumber}</span>
                            </div>
                            <div className={styles.details.row}>
                                <span className={styles.details.label(isDark)}>Материал</span>
                                <span className={styles.details.value(isDark)}>{product.details.material}</span>
                            </div>
                            <div className={styles.details.row}>
                                <span className={styles.details.label(isDark)}>Размер</span>
                                <span className={styles.details.value(isDark)}>{product.details.size}</span>
                            </div>
                            <div className={styles.details.row}>
                                <span className={styles.details.label(isDark)}>Доступно</span>
                                <span className={styles.details.value(isDark)}>{product.details.availability}</span>
                            </div>
                        </div>
                    </div>
                )}

                {/* {product.colors && product.colors.length > 0 && (
                    <div className="mb-6">
                        <h3 className="mb-2">Цвета:</h3>
                        <div className="flex gap-2">
                            {product.colors.map((color, index) => {
                                // Map color names to hex values (simplified)
                                const colorMap: Record<string, string> = {
                                    'Белый': '#FFFFFF',
                                    'Brown': '#8B4513',
                                    'Черный': '#000000',
                                    'Серебро': '#C0C0C0',
                                    'Синий': '#0000FF',
                                    'Красный': '#FF0000',
                                    'Зеленый': '#008000'
                                };

                                const colorHex = colorMap[color] || '#CCCCCC';

                                return (
                                    <button
                                        key={index}
                                        onClick={() => setSelectedColor(color)}
                                        className={`w-10 h-10 rounded border ${
                                            selectedColor === color
                                                ? 'border-blue-500 ring-2 ring-blue-200'
                                                : isDark ? 'border-gray-600' : 'border-gray-300'
                                        }`}
                                        style={{ backgroundColor: colorHex }}
                                    />
                                );
                            })}
                        </div>
                    </div>
                )} */}

            </div>

            <div className={`fixed bottom-0 left-0 right-0 p-4 ${
                isDark ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
            }`}>
                <div className="flex gap-2 items-center">
                    <div className="flex">
                        <Button
                            variant="outline"
                            size="icon"
                            onClick={decrementQuantity}
                            disabled={quantity <= 1}
                            className={`h-10 rounded-r-none rounded-l-lg border-r-0 bg-white ${
                                isDark ? 'border-gray-600' : 'border-gray-300'
                            }`}
                        >
                            <MinusIcon className="h-4 w-4" />
                        </Button>
                        <div className={`w-10 h-10 flex items-center justify-center border-t border-b ${
                            isDark ? 'border-gray-600' : 'border-gray-300'
                        }`}>
                            {quantity}
                        </div>
                        <Button
                            variant="outline"
                            size="icon"
                            onClick={incrementQuantity}
                            className={`h-10 rounded-l-none rounded-r-lg border-l-0 bg-white ${
                                isDark ? 'border-gray-600' : 'border-gray-300'
                            }`}
                        >
                            <PlusIcon className="h-4 w-4" />
                        </Button>
                    </div>
                    <Button
                        {...styles.buttons.primary(themeParams)}
                        onClick={handleAddToCart}
                        disabled={_isAddingToCart}
                    >
                        {_isAddingToCart ? 'Добавление...' : `Добавить в корзину (${quantity})`}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ProductDetailPage;