import { useEffect, useState, useCallback } from 'react';
import { ApiClient } from '../client';

export const api = new ApiClient('https://admin.larez.shop');

export function useApi() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthError, setIsAuthError] = useState(false);

  const handleAuthError = useCallback(() => {
    setIsAuthenticated(false);
    setIsAuthError(true);
  }, []);

  useEffect(() => {
    const initAuth = async () => {
      try {
        // Получаем initData из Telegram WebApp
        const webApp = (window as any).Telegram?.WebApp;
        if (webApp) {
          const initData = webApp.initData;
          await api.authenticate(initData);
          setIsAuthenticated(true);
          setIsAuthError(false);
        }
      } catch (error) {
        console.error('Authentication failed:', error);
        handleAuthError();
      }
    };

    // Слушаем событие ошибки аутентификации
    window.addEventListener('auth-error', handleAuthError);
    
    initAuth();

    return () => {
      window.removeEventListener('auth-error', handleAuthError);
    };
  }, [handleAuthError]);

  // Функция для повторной попытки аутентификации
  const retryAuth = async () => {
    try {
      const webApp = (window as any).Telegram?.WebApp;
      if (webApp) {
        const initData = webApp.initData;
        await api.authenticate(initData);
        setIsAuthenticated(true);
        setIsAuthError(false);
      }
    } catch (error) {
      console.error('Retry authentication failed:', error);
      handleAuthError();
    }
  };

  return {
    api,
    isAuthenticated,
    isAuthError,
    retryAuth
  };
} 