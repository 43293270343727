import React from 'react';
import { Button } from '@/components/ui/button';
import { useTheme } from '../contexts/ThemeContext';

export interface CheckoutFormData {
    name: string;
    phone: string;
    email: string;
    address: string;
}

export interface CheckoutFormProps {
    onSubmit: (formData: CheckoutFormData) => void;
    // onBack: () => void;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({ onSubmit }) => {
    const { isDark, themeParams } = useTheme();
    const [formData, setFormData] = React.useState<CheckoutFormData>({
        name: '',
        phone: '+7',
        email: '',
        address: ''
    });

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(formData);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    return (
        <div className="p-4">
            <form onSubmit={handleSubmit} className="space-y-4">
                <input
                    required
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Имя *"
                    className={`w-full p-3 rounded-lg border ${
                        isDark 
                            ? 'bg-gray-800 border-gray-700 text-white' 
                            : 'bg-white border-gray-300 text-gray-900'
                    }`}
                />
                
                <input
                    required
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Телефон *"
                    className={`w-full p-3 rounded-lg border ${
                        isDark 
                            ? 'bg-gray-800 border-gray-700 text-white' 
                            : 'bg-white border-gray-300 text-gray-900'
                    }`}
                />
                
                <input
                    required
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Почта *"
                    className={`w-full p-3 rounded-lg border ${
                        isDark 
                            ? 'bg-gray-800 border-gray-700 text-white' 
                            : 'bg-white border-gray-300 text-gray-900'
                    }`}
                />

                <textarea
                    required
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    placeholder="Адрес доставки ( с индексом ) или пункта выдачи *"
                    rows={3}
                    className={`w-full p-3 rounded-lg border ${
                        isDark 
                            ? 'bg-gray-800 border-gray-700 text-white' 
                            : 'bg-white border-gray-300 text-gray-900'
                    }`}
                />

                <div className="fixed bottom-0 left-0 right-0 p-4 border-t bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700">
                    <Button
                        type="submit"
                        className="w-full py-3 rounded-md"
                        style={{
                            backgroundColor: themeParams?.button_color || '#3390EC',
                            color: themeParams?.button_text_color || '#FFFFFF'
                        }}
                    >
                        Оформить заказ
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default CheckoutForm; 