export const styles = {
    // Типографика
    typography: {
        // Заголовки
        h1: "text-[34px] font-inter leading-[100%] tracking-[-0.45px] font-bold",
        h2: "text-[22px] font-inter leading-[28px] tracking-[-0.35px] font-semibold",
        
        // Текст
        body: "font-inter text-[17px] leading-[22px] tracking-[-0.41px] font-normal",
        small: "font-normal text-[15px] font-inter leading-[20px] tracking-[-0.24px]",
        
        // Цены
        price: {
            large: "text-[34px] font-inter leading-[100%] tracking-[-0.45px] font-semibold",
            small: "font-normal text-[15px] leading-[20px] tracking-[-0.24px] text-[#85858B]"
        }
    },

    // Контейнеры
    containers: {
        page: (isDark: boolean) => 
            `min-h-screen pb-24 ${isDark ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`,
        
        card: (isDark: boolean) => 
            `rounded-lg overflow-hidden shadow cursor-pointer ${
                isDark ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'
            }`,

        imageContainer: (isDark: boolean) => 
            `${isDark ? 'bg-gray-800' : 'bg-white'} p-4 rounded-lg`,
        detailsRow: (isDark: boolean) => `divide-y ${isDark ? 'divide-gray-700' : 'divide-gray-200'}`
    },

    // Изображения
    images: {
        productCard: "object-cover w-full h-full absolute inset-0",
        productDetail: "w-full object-cover rounded-lg h-[25rem]"
    },

    // Детали продукта
    details: {
        row: "flex justify-between py-2",
        label: (isDark: boolean) => 
            `${isDark ? 'text-[#85858B]' : 'text-[#85858B]'} font-normal font-inter leading-[22px] tracking-[-0.41px] text-[17px]`,
        value: (isDark: boolean) => 
            `${isDark ? 'text-black' : 'text-black'} font-normal font-inter leading-[22px] tracking-[-0.41px] text-[17px]`
    },

    // Кнопки
    buttons: {
        primary: (themeParams: any) => ({
            className: "flex-1 py-2 rounded-md",
            style: {
                backgroundColor: themeParams.button_color || '#3390EC',
                color: themeParams.button_text_color || '#FFFFFF'
            }
        })
    }
}; 