import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { getTelegramColorScheme, getTelegramThemeParams } from '../utils/telegram';

type ThemeType = 'light' | 'dark';

interface ThemeParams {
    bg_color?: string;
    text_color?: string;
    hint_color?: string;
    link_color?: string;
    button_color?: string;
    button_text_color?: string;
    secondary_bg_color?: string;
    [key: string]: string | undefined;
}

interface ThemeContextType {
    theme: ThemeType;
    themeParams: ThemeParams;
    toggleTheme: () => void;
    isDark: boolean;
    colors: Record<string, string>;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useTheme = (): ThemeContextType => {
    const context = useContext(ThemeContext);
    if (context === undefined) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};

interface ThemeProviderProps {
    children: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
    // Initialize with Telegram's color scheme or fallback to 'light'
    const [theme, setTheme] = useState<ThemeType>(() =>
        getTelegramColorScheme() as ThemeType
    );

    // Get Telegram theme parameters
    const [themeParams, setThemeParams] = useState<ThemeParams>(() =>
        getTelegramThemeParams()
    );

    // CSS variables for theme colors
    const lightThemeColors: Record<string, string> = {
        '--background': '#f5f5f5',
        '--card': '#ffffff',
        '--card-foreground': '#0f172a',
        '--primary': themeParams.button_color || '#3390ec',
        '--primary-foreground': '#ffffff',
        '--secondary': '#f1f5f9',
        '--secondary-foreground': '#0f172a',
        '--accent': '#f1f5f9',
        '--accent-foreground': '#0f172a',
        '--destructive': '#ff4d4f',
        '--destructive-foreground': '#ffffff',
        '--muted': '#f1f5f9',
        '--muted-foreground': '#64748b',
        '--border': '#e2e8f0',
        '--input': '#e2e8f0',
        '--ring': themeParams.button_color || '#3390ec',
        '--text': '#0f172a',
        '--header-bg': '#ffffff',
        '--button-text': '#ffffff',
    };

    const darkThemeColors: Record<string, string> = {
        '--background': '#0f172a',
        '--card': '#1e293b',
        '--card-foreground': '#f8fafc',
        '--primary': themeParams.button_color || '#3390ec',
        '--primary-foreground': '#ffffff',
        '--secondary': '#334155',
        '--secondary-foreground': '#f8fafc',
        '--accent': '#334155',
        '--accent-foreground': '#f8fafc',
        '--destructive': '#ff4d4f',
        '--destructive-foreground': '#ffffff',
        '--muted': '#334155',
        '--muted-foreground': '#94a3b8',
        '--border': '#334155',
        '--input': '#334155',
        '--ring': themeParams.button_color || '#3390ec',
        '--text': '#f8fafc',
        '--header-bg': '#1e293b',
        '--button-text': '#ffffff',
    };

    // Toggle between light and dark themes
    const toggleTheme = () => {
        setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
    };

    // Listen for changes in Telegram's color scheme
    useEffect(() => {
        const handleThemeChange = () => {
            const newColorScheme = getTelegramColorScheme() as ThemeType;
            const newThemeParams = getTelegramThemeParams();

            setTheme(newColorScheme);
            setThemeParams(newThemeParams);
        };

        // Add event listener for Telegram theme changes if available
        if (window.Telegram?.WebApp) {
            window.Telegram.WebApp.onEvent('themeChanged', handleThemeChange);

            return () => {
                window.Telegram?.WebApp?.offEvent('themeChanged', handleThemeChange);
            };
        }
    }, []);

    // Apply theme colors to document root
    useEffect(() => {
        const colors = theme === 'dark' ? darkThemeColors : lightThemeColors;

        // Apply colors to :root
        Object.entries(colors).forEach(([property, value]) => {
            document.documentElement.style.setProperty(property, value);
        });

        // Add theme class to body
        document.body.classList.remove('light-theme', 'dark-theme');
        document.body.classList.add(`${theme}-theme`);
    }, [theme, themeParams]);

    const value = {
        theme,
        themeParams,
        toggleTheme,
        isDark: theme === 'dark',
        colors: theme === 'dark' ? darkThemeColors : lightThemeColors
    };

    return (
        <ThemeContext.Provider value={value}>
            {children}
        </ThemeContext.Provider>
    );
};