import React, { createContext, useContext, useEffect, useState } from 'react';
import { useApi } from '../api/hooks/useApi';
import { Product, FilterResponse } from '../api/types';
import { FilterOptions } from '../types';

interface ProductContextType {
    products: Product[];
    filters: FilterOptions;
    updateFilters: (newFilters: Partial<FilterOptions>) => void;
    getProductById: (id: number) => Promise<Product | null>;
    availableFilters: FilterResponse | null;
}

const ProductContext = createContext<ProductContextType | undefined>(undefined);

export const useProducts = (): ProductContextType => {
    const context = useContext(ProductContext);
    if (context === undefined) {
        throw new Error('useProducts must be used within a ProductProvider');
    }
    return context;
};

interface ProductProviderProps {
    children: React.ReactNode;
}

export const ProductProvider: React.FC<ProductProviderProps> = ({ children }) => {
    const { api } = useApi();
    const [_, setAllProducts] = useState<Product[]>([]);
    const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
    const [filters, setFilters] = useState<FilterOptions>({
        priceRange: [0, 10000],
        colors: [],
        category: 'all'
    });
    const [availableFilters, setAvailableFilters] = useState<FilterResponse | null>(null);

    // Загрузка фильтров
    useEffect(() => {
        const loadFilters = async () => {
            try {
                const filterData = await api.getFilters();
                setAvailableFilters(filterData);
                setFilters(prev => ({
                    ...prev,
                    priceRange: [filterData.price_range.min_price, filterData.price_range.max_price]
                }));
            } catch (error) {
                console.error('Failed to load filters:', error);
            }
        };

        loadFilters();
    }, []);

    // Загрузка продуктов с учетом фильтров
    useEffect(() => {
        const loadProducts = async () => {
            try {
                const filterParams = {
                    min_price: filters.priceRange[0],
                    max_price: filters.priceRange[1],
                    colors: filters.colors.length > 0 ? filters.colors.join(';') : undefined
                };

                const response = await api.getProducts(filterParams);
                setAllProducts(response.results);
                setFilteredProducts(response.results);
            } catch (error) {
                console.error('Failed to load products:', error);
            }
        };

        loadProducts();
    }, [filters, api]);

    const getProductById = async (id: number) => {
        try {
            return await api.getProduct(id.toString());
        } catch (error) {
            console.error('Failed to load product:', error);
            return null;
        }
    };

    const updateFilters = (newFilters: Partial<FilterOptions>) => {
        setFilters(prev => ({
            ...prev,
            ...newFilters
        }));
    };

    return (
        <ProductContext.Provider value={{
            products: filteredProducts,
            filters,
            updateFilters,
            getProductById,
            availableFilters
        }}>
            {children}
        </ProductContext.Provider>
    );
};