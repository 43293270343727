import React, { createContext, useContext, useState, useEffect } from 'react';
import { useApi } from '../api/hooks/useApi';
import { CartResponse } from '../api/types';

interface CartContextType {
    cart: CartResponse | null;
    refreshCart: () => Promise<void>;
    addToCart: (productId: string, quantity: number) => Promise<void>;
    removeFromCart: (productId: string) => Promise<void>;
    changeQuantity: (productId: string, quantity: number) => Promise<void>;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { api, isAuthenticated } = useApi();
    const [cart, setCart] = useState<CartResponse | null>(null);

    const refreshCart = async () => {
        try {
            const cartData = await api.getCart();
            setCart(cartData);
        } catch (error) {
            console.error('Failed to load cart:', error);
            setCart(null);
        }
    };

    useEffect(() => {
        refreshCart();
    }, [isAuthenticated]);

    const addToCart = async (productId: string, quantity: number) => {
        try {
            await api.addToCart(productId, quantity);
            await refreshCart();
        } catch (error) {
            console.error('Failed to add to cart:', error);
        }
    };

    const removeFromCart = async (productId: string) => {
        try {
            await api.removeFromCart(productId);
            await refreshCart();
        } catch (error) {
            console.error('Failed to remove from cart:', error);
        }
    };

    const changeQuantity = async (productId: string, quantity: number) => {
        try {
            await api.changeQuantity(productId, quantity);
            await refreshCart();
        } catch (error) {
            console.error('Failed to change quantity:', error);
        }
    };

    return (
        <CartContext.Provider value={{
            cart,
            refreshCart,
            addToCart,
            removeFromCart,
            changeQuantity
        }}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => {
    const context = useContext(CartContext);
    if (context === undefined) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
};