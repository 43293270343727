import { useNavigate } from 'react-router-dom';

export const useNavigation = () => {
    const navigate = useNavigate();

    return {
        onBackClick: () => navigate(-1),
        onCartClick: () => navigate('/cart'),
        onProductSelect: (id: number) => {
            console.log('useNavigation - navigating to product with id:', id);
            if (!id || id <= 0) {
                console.error('Invalid product ID:', id);
                return;
            }
            navigate(`/product/${id}`);
        },
        onApply: () => navigate('/'),
        onClose: () => navigate(-1),
        onCheckout: () => {
            // Здесь будет логика оформления заказа
            navigate('/');
        }
    };
}; 