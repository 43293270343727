import React, { useEffect } from 'react';
import { ChevronLeft, ShoppingCart } from 'lucide-react';
import { useTheme } from '../contexts/ThemeContext';
import { setupTelegramBackButton } from '../utils/telegram';

interface HeaderProps {
    title: string;
    subtitle?: string;
    showBackButton?: boolean;
    showCart?: boolean;
    onBackClick?: () => void;
    onCartClick?: () => void;
}

const Header: React.FC<HeaderProps> = ({
                                           title,
                                           subtitle,
                                           showBackButton = false,
                                           showCart = false,
                                           onBackClick,
                                           onCartClick
                                       }) => {
    const { isDark } = useTheme();

    // Set up Telegram back button if needed
    useEffect(() => {
        if (showBackButton && onBackClick) {
            setupTelegramBackButton(true, onBackClick);

            return () => {
                setupTelegramBackButton(false, onBackClick);
            };
        }
    }, [showBackButton, onBackClick]);

    const handleBackClick = () => {
        if (onBackClick) {
            onBackClick();
        }
    };

    const handleCartClick = () => {
        if (onCartClick) {
            onCartClick();
        }
    };

    return (
        <header className={`p-4 flex items-center justify-between ${isDark ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'} flex-row`}>
            {showBackButton ? (
                <div
                    className="flex items-center cursor-pointer translate-y-[1px]"
                    onClick={handleBackClick}
                >
                    <ChevronLeft className="w-6 h-6 text-blue-500" />
                </div>
            ) : (
                <div className="w-6"></div>
            )}
            <div className="text-center flex-1 flex flex-row items-center justify-center gap-2">
                <div className="flex flex-row items-center content-center justify-between gap-2 flex-grow">
                    <h1 className="text-[20px] leading-[22px] tracking-[-0.43px] font-inter font-semibold align-middle">{title}</h1>
                    {showCart && (
                        <ShoppingCart
                            className="w-6 h-6 cursor-pointer translate-y-[1px]"
                            onClick={handleCartClick}
                        />
                    )}
                </div>
                {subtitle && <p className="text-xs text-gray-400">{subtitle}</p>}
            </div>
        </header>
    );
};

export default Header;