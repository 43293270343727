import React from 'react';
import { useApi } from '../api/hooks/useApi';
import { useTheme } from '../contexts/ThemeContext';

export const AuthErrorHandler: React.FC = () => {
  const { isAuthError, retryAuth } = useApi();
  const { isDark } = useTheme();

  if (!isAuthError) {
    return null;
  }

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center p-4 ${
      isDark ? 'bg-gray-900/80' : 'bg-gray-100/80'
    }`}>
      <div className={`rounded-lg p-6 max-w-sm w-full ${
        isDark ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'
      }`}>
        <h3 className="text-lg font-semibold mb-2">Ошибка аутентификации</h3>
        <p className="text-sm mb-4">
          Произошла ошибка при проверке вашей авторизации. Пожалуйста, попробуйте снова.
        </p>
        <button
          onClick={retryAuth}
          className="w-full py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
        >
          Повторить
        </button>
      </div>
    </div>
  );
}; 