import React, { useState, useEffect } from 'react';
// import { Button } from '@/components/ui/button.tsx';
// import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs.tsx';
import { useTheme } from '../contexts/ThemeContext.tsx';
import { useProducts } from '../contexts/ProductContext.tsx';
import { setupTelegramMainButton } from '../utils/telegram.ts';
import Header from '../components/Header.tsx';
import FilterPage from './FilterPage.tsx';
import { Product } from '../types.ts';
import mainLogo from '../assets/main_logo.png';
import { styles } from '../styles/styles';

interface ProductCardProps {
    product: Product;
    onClick: (id: number) => void;
}

const ProductCard: React.FC<ProductCardProps> = ({ product, onClick }) => {
    const { isDark } = useTheme();

    return (
        <div className={styles.containers.card(isDark)} onClick={() => {
            console.log('ProductCard clicked:', product.id);
            onClick(product.id);
        }}>
            <div className="aspect-square relative overflow-hidden bg-gray-100 rounded-lg">
                <img
                    src={product.image}
                    alt={product.name}
                    className={styles.images.productCard}
                />
            </div>
            <div className="p-3">
                <h3 className={styles.typography.small}>{product.name}</h3>
                <p className={styles.typography.price.small}>₽{product.price}</p>
            </div>
        </div>
    );
};

interface ProductListingPageProps {
    onProductSelect: (id: number) => void;
    onCartClick: () => void;
}

const ProductListingPage: React.FC<ProductListingPageProps> = ({
                                                                   onProductSelect,
                                                                   onCartClick
                                                               }) => {
    const { isDark, themeParams } = useTheme();
    const { products, filters, updateFilters } = useProducts();
    const [_, setActiveTab] = useState('all');
    const [showFilter, setShowFilter] = useState(false);

    // Set up Telegram's main button
    useEffect(() => {
        setupTelegramMainButton({
            text: 'Купить на 40%',
            color: themeParams.button_color || '#3390EC',
            textColor: themeParams.button_text_color || '#FFFFFF',
            isVisible: false,
            isActive: false,
            onClick: () => console.log('Buy with 40% discount')
        });

        return () => {
            setupTelegramMainButton({
                isVisible: false
            });
        };
    }, [themeParams]);

    // const handleTabChange = (value: string) => {
    //     setActiveTab(value);
    //     if (value === 'filter') {
    //         setShowFilter(true);
    //     }
    // };

    const handleApplyFilters = (priceRange: [number, number], selectedColors: string[]) => {
        updateFilters({
            priceRange,
            colors: selectedColors
        });
        setShowFilter(false);
        setActiveTab('all');
    };

    const handleCloseFilter = () => {
        setShowFilter(false);
        setActiveTab('all');
    };

    const handleProductSelect = (id: number) => {
        console.log('handleProductSelect called with id:', id);
        onProductSelect(id);
    };

    if (showFilter) {
        return (
            <FilterPage
                onApply={handleApplyFilters}
                onClose={handleCloseFilter}
                initialPriceRange={filters.priceRange}
                initialColors={filters.colors}
            />
        );
    }

    return (
        <div className={styles.containers.page(isDark)}>
            <Header
                title="LAREZ"
                showCart
                onCartClick={onCartClick}
            />

            <div className="px-4 pt-2">
                <div className="mb-6">
                    <div className="-mx-4 mb-6">
                        <img src={mainLogo} alt="Larez" className="w-full" />
                    </div>
                    <div className="">
                        <h2 className={`${styles.typography.h1} mb-3`}>Игрушки из ларца</h2>
                        <p className={`${styles.typography.body} text-zinc-500`}>
                            Каждая игрушка сделана вручную, поэтому она уникальна и ценна.
                        </p>
                    </div>
                </div>

                <div className="mb-6">
                    <div className="flex flex-row gap-2">
                        <div
                            onClick={() => {
                                setShowFilter(true);
                                setActiveTab('filter');
                            }}
                            className={`text-left flex items-center cursor-pointer transition-colors duration-200 hover:text-gray-900 ${
                                isDark ? 'text-gray-400' : 'text-gray-500'
                            }`}
                        >
                            <svg width="20" height="14" viewBox="0 0 20 14" fill="none" className="w-4 h-4 mr-2">
                                <path 
                                    d="M8.125 12.5625C8.125 12.3139 8.22377 12.0754 8.39959 11.8996C8.5754 11.7238 8.81386 11.625 9.0625 11.625H10.9375C11.1861 11.625 11.4246 11.7238 11.6004 11.8996C11.7762 12.0754 11.875 12.3139 11.875 12.5625C11.875 12.8111 11.7762 13.0496 11.6004 13.2254C11.4246 13.4012 11.1861 13.5 10.9375 13.5H9.0625C8.81386 13.5 8.5754 13.4012 8.39959 13.2254C8.22377 13.0496 8.125 12.8111 8.125 12.5625ZM4.375 6.9375C4.375 6.68886 4.47377 6.4504 4.64959 6.27459C4.8254 6.09877 5.06386 6 5.3125 6H14.6875C14.9361 6 15.1746 6.09877 15.3504 6.27459C15.5262 6.4504 15.625 6.68886 15.625 6.9375C15.625 7.18614 15.5262 7.4246 15.3504 7.60041C15.1746 7.77623 14.9361 7.875 14.6875 7.875H5.3125C5.06386 7.875 4.8254 7.77623 4.64959 7.60041C4.47377 7.4246 4.375 7.18614 4.375 6.9375ZM0.625 1.3125C0.625 1.06386 0.723772 0.825403 0.899588 0.649588C1.0754 0.473772 1.31386 0.375 1.5625 0.375H18.4375C18.6861 0.375 18.9246 0.473772 19.1004 0.649588C19.2762 0.825403 19.375 1.06386 19.375 1.3125C19.375 1.56114 19.2762 1.7996 19.1004 1.97541C18.9246 2.15123 18.6861 2.25 18.4375 2.25H1.5625C1.31386 2.25 1.0754 2.15123 0.899588 1.97541C0.723772 1.7996 0.625 1.56114 0.625 1.3125Z" 
                                    fill="currentColor"
                                    fillOpacity="0.8"
                                />
                            </svg>
                        </div>
                        <div 
                            onClick={() => {
                                setShowFilter(false);
                                setActiveTab('all');
                                updateFilters({ priceRange: [0, 100000], colors: [] });
                            }}
                            className={`text-left cursor-pointer transition-colors duration-200 hover:text-gray-900 font-inter text-[17px] leading-[22px] tracking-[-0.4px] font-normal ${
                                isDark ? 'text-gray-400' : 'text-gray-500'
                            }`}
                        >
                            Все
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-2 gap-3">
                    {products.map((product) => (
                        <ProductCard
                            key={product.id}
                            product={product}
                            onClick={handleProductSelect}
                        />
                    ))}
                </div>
            </div>

            {/* <div className={`fixed bottom-0 left-0 right-0 p-4 border-t ${
                isDark ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
            }`}>
                <Button
                    className="w-full py-3 rounded-md"
                    style={{
                        backgroundColor: themeParams.button_color || '#3390EC',
                        color: themeParams.button_text_color || '#FFFFFF'
                    }}
                >
                    Купить на 40%
                </Button>
            </div> */}
        </div>
    );
};

export default ProductListingPage;