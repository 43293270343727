// Define the Telegram WebApp interface
interface TelegramWebApp {
    initData: string;
    expand: () => void;
    enableClosingConfirmation: () => void;
    ready: () => void;
    close: () => void;
    colorScheme: 'light' | 'dark';
    themeParams: {
        bg_color?: string;
        text_color?: string;
        hint_color?: string;
        link_color?: string;
        button_color?: string;
        button_text_color?: string;
        secondary_bg_color?: string;
        [key: string]: string | undefined;
    };
    sendData: (data: string) => void;
    showPopup: (params: {
        message: string;
        buttons: Array<{
            id: string;
            type?: string;
            text: string;
        }>;
    }) => void;
    BackButton: {
        show: () => void;
        hide: () => void;
        onClick: (callback: () => void) => void;
        offClick: (callback: () => void) => void;
    };
    MainButton: {
        text: string;
        color: string;
        textColor: string;
        isVisible: boolean;
        isActive: boolean;
        isProgressVisible: boolean;
        hasShineEffect: boolean;
        type: string;
        setText: (text: string) => void;
        setParams: (params: {
            text?: string;
            color?: string;
            text_color?: string;
            is_active?: boolean;
            is_visible?: boolean;
        }) => void;
        show: () => void;
        hide: () => void;
        enable: () => void;
        disable: () => void;
        showProgress: (leaveActive?: boolean) => void;
        hideProgress: () => void;
        onClick: (callback: () => void) => void;
        offClick: (callback: () => void) => void;
    };
    onEvent: (eventType: string, callback: () => void) => void;
    offEvent: (eventType: string, callback: () => void) => void;
}

// Define Telegram global object
declare global {
    interface Window {
        Telegram?: {
            WebApp?: TelegramWebApp;
        };
    }
}

// Access the Telegram WebApp instance
export const tg = window.Telegram?.WebApp;
console.log('Telegram WebApp instance:', tg);

// Initialize Telegram WebApp
export function initTelegramApp(): boolean {
    console.log('Initializing Telegram WebApp...');
    console.log('window.Telegram:', window.Telegram);
    console.log('window.Telegram.WebApp:', window.Telegram?.WebApp);
    
    if (tg) {
        console.log('Telegram WebApp found, initData:', tg.initData);
        // Expand the WebApp to full height
        tg.expand();

        // Enable closing confirmation dialog when items are in the cart
        tg.enableClosingConfirmation();

        // Tell Telegram WebApp we're ready
        tg.ready();

        return true;
    }
    console.log('Telegram WebApp not found');
    return false;
}

// Get color scheme (dark or light) from Telegram
export function getTelegramColorScheme(): string {
    if (!tg) return 'light';
    return tg.colorScheme || 'light';
}

// Get theme params from Telegram
export function getTelegramThemeParams(): Record<string, string | undefined> {
    if (!tg) return {};
    return tg.themeParams || {};
}

// Send data back to Telegram when order is completed
export function sendDataToTelegram(data: unknown): boolean {
    if (!tg) return false;
    tg.sendData(JSON.stringify(data));
    return true;
}

// Close the WebApp
export function closeTelegramWebApp(): boolean {
    if (!tg) return false;
    tg.close();
    return true;
}

// Show a popup message via Telegram
export function showTelegramPopup(
    message: string,
    buttons: Array<{ id: string; type?: string; text: string }> = []
): boolean {
    if (!tg) return false;
    tg.showPopup({
        message,
        buttons: buttons.map(button => ({
            id: button.id,
            type: button.type || 'default',
            text: button.text
        }))
    });
    return true;
}

// Use native Telegram back button
export function setupTelegramBackButton(isVisible: boolean, onClick: () => void): boolean {
    if (!tg) return false;

    if (isVisible) {
        tg.BackButton.show();
        tg.BackButton.onClick(onClick);
    } else {
        tg.BackButton.hide();
        tg.BackButton.offClick(onClick);
    }

    return true;
}

// Use Telegram's MainButton
interface MainButtonConfig {
    text?: string;
    color?: string;
    textColor?: string;
    isVisible?: boolean;
    isActive?: boolean;
    onClick?: () => void;
}

export function setupTelegramMainButton(config: MainButtonConfig): boolean {
    if (!tg) return false;

    const { text, color, textColor, isVisible, isActive, onClick } = config;

    // Используем setParams для установки всех параметров сразу
    const params: any = {};
    if (text !== undefined) params.text = text;
    if (color !== undefined) params.color = color;
    if (textColor !== undefined) params.text_color = textColor;
    if (isActive !== undefined) params.is_active = isActive;
    if (isVisible !== undefined) params.is_visible = isVisible;

    if (Object.keys(params).length > 0) {
        tg.MainButton.setParams(params);
    }

    if (onClick) {
        tg.MainButton.onClick(onClick);
    }

    return true;
}

// Проверка, запущено ли приложение в Telegram WebApp
export function isTelegramWebApp(): boolean {
    console.log('Checking if running in Telegram WebApp...');
    console.log('window.Telegram:', window.Telegram);
    console.log('window.Telegram.WebApp:', window.Telegram?.WebApp);
    
    const webApp = window.Telegram?.WebApp;
    console.log('isTelegramWebApp - webApp:', webApp);
    if (!webApp) {
        console.log('WebApp not found');
        return false;
    }
    console.log('isTelegramWebApp - webApp.initData:', webApp.initData);
    
    // Проверяем наличие initData для подтверждения, что это действительно Telegram
    return !!webApp.initData;
}
