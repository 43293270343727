import React, { useEffect, useState } from 'react';
import { MinusIcon, PlusIcon } from "@radix-ui/react-icons";
import { Button } from '@/components/ui/button.tsx';
import { useTheme } from '../contexts/ThemeContext.tsx';
import { useCart } from '../contexts/CartContext.tsx';
import { setupTelegramMainButton, sendDataToTelegram } from '../utils/telegram.ts';
import Header from '../components/Header.tsx';
import CheckoutForm from '../components/CheckoutForm.tsx';
import { CheckoutFormData } from '../components/CheckoutForm';

interface CartItemProps {
    id: number;
    name: string;
    price: number;
    quantity: number;
    image: string;
    onIncrease: (id: number) => void;
    onDecrease: (id: number) => void;
}

const CartItem: React.FC<CartItemProps> = ({
                                               id,
                                               name,
                                               price,
                                               quantity,
                                               image,
                                               onIncrease,
                                               onDecrease
                                           }) => {
    // const { isDark } = useTheme();

    return (
        <div className="flex w-full">
            <div className="w-1/4 min-w-[80px] max-w-[100px] self-stretch flex items-center">
                <img
                    src={image}
                    alt={name}
                    className="w-full h-full object-cover rounded-xl"
                />
            </div>
            <div className="flex-1 pl-4 flex flex-col">
                <h3 className="font-normal mb-1 text-[17px] font-inter leading-[22px] tracking-[-0.41px]">{name}</h3>
                <p className="font-bold text-[17px] font-inter leading-[22px] tracking-[-0.41px] mb-2">₽{price}</p>
                <div className="flex items-center justify-between mb-3">
                    <span className="text-[17px] font-inter leading-[22px] tracking-[-0.41px] font-medium">
                        <span className="text-[17px] font-inter leading-[22px] tracking-[-0.41px] text-[#85858B] font-normal">
                            шт:&nbsp;&nbsp;
                        </span>
                        {quantity}
                    </span>
                    <div className="flex items-center rounded-lg overflow-hidden bg-gray-200 dark:bg-gray-700">
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => onDecrease(id)}
                            className="w-6 h-6 flex items-center justify-center rounded-none border-none bg-gray-200 dark:bg-gray-700"
                        >
                            <MinusIcon className="w-3 h-3 text-[#85858B]" />
                        </Button>
                        <div className="h-4 w-px bg-gray-300 dark:bg-gray-100"></div>
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => onIncrease(id)}
                            className="w-6 h-6 flex items-center justify-center rounded-none border-none bg-gray-200 dark:bg-gray-700"
                        >
                            <PlusIcon className="w-3 h-3 text-[#85858B]" />
                        </Button>
                    </div>
                </div>
                <div className="h-px bg-gray-200 dark:bg-gray-700 w-full mt-auto" />
            </div>
        </div>
    );
};

interface CartPageProps {
    onBackClick: () => void;
    onCheckout: () => void;
}

const CartPage: React.FC<CartPageProps> = ({
                                               onBackClick,
                                               onCheckout
                                           }) => {
    const { isDark, themeParams } = useTheme();
    const { cart, changeQuantity, refreshCart } = useCart();
    const [showCheckoutForm, setShowCheckoutForm] = useState(false);

    useEffect(() => {
        refreshCart();
    }, []);

    const handleCheckout = React.useCallback((): void => {
        setShowCheckoutForm(true);
    }, []);

    const handleFormSubmit = (formData: CheckoutFormData): void => {
        if (!cart) return;

        sendDataToTelegram({
            action: 'checkout',
            items: cart.items.map(item => ({
                id: item.id,
                name: item.product_name,
                price: item.price,
                quantity: item.quantity
            })),
            formData
        });

        onCheckout();
    };

    useEffect(() => {
        if (!cart) return;

        setupTelegramMainButton({
            text: 'Продолжить',
            color: themeParams?.button_color || '#3390EC',
            textColor: themeParams?.button_text_color || '#FFFFFF',
            isVisible: cart.items.length > 0,
            isActive: cart.items.length > 0,
            onClick: handleCheckout
        });

        return () => {
            setupTelegramMainButton({
                isVisible: false
            });
        };
    }, [cart, themeParams, handleCheckout]);

    return (
        <div className={`min-h-screen ${isDark ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
            <Header
                title={showCheckoutForm ? "Оформление заказа" : "Корзина"}
                showBackButton
                onBackClick={showCheckoutForm ? () => setShowCheckoutForm(false) : onBackClick}
            />

            {showCheckoutForm ? (
                <CheckoutForm 
                    onSubmit={handleFormSubmit}
                    // onBack={() => setShowCheckoutForm(false)}
                />
            ) : (
                <div className="p-4">
                    <h1 className="text-2xl font-bold mb-6 font-inter leading-[100%] tracking-[-0.45px] text-[34px]">Корзина</h1>

                    {cart?.items.length === 0 ? (
                        <div className={`p-6 text-center rounded-lg ${
                            isDark ? 'bg-gray-800' : 'bg-white'
                        }`}>
                            <p className="mb-4">Ваша корзина пуста</p>
                            <Button
                                onClick={onBackClick}
                                className="py-2 px-4 rounded-md"
                                style={{
                                    backgroundColor: themeParams.button_color || '#3390EC',
                                    color: themeParams.button_text_color || '#FFFFFF'
                                }}
                            >
                                Перейти к покупкам
                            </Button>
                        </div>
                    ) : (
                        <>
                            <div className={`${
                                isDark ? 'bg-gray-800 divide-gray-700' : 'bg-white divide-gray-200'
                            }`}>
                                {cart?.items.map((item) => (
                                    <CartItem
                                        key={item.id}
                                        id={item.id}
                                        name={item.product_name}
                                        price={item.price}
                                        quantity={item.quantity}
                                        image={item.preview_image}
                                        onIncrease={(id) => changeQuantity(id.toString(), item.quantity + 1)}
                                        onDecrease={(id) => changeQuantity(id.toString(), item.quantity - 1)}
                                    />
                                ))}
                            </div>

                            <div className="mt-8 mb-24">
                                <div className="flex justify-between mb-2">
                                    <span className={isDark ? 'text-gray-300' : 'text-gray-600'}>Итого:</span>
                                    <span className="font-bold">₽{cart?.total_price}</span>
                                </div>
                                <div className="flex justify-between items-center text-sm">
                                    <span className={isDark ? 'text-gray-300' : 'text-gray-600'}>Доставка:</span>
                                    <span className={isDark ? 'text-gray-300' : 'text-gray-600'}>Рассчитывается отдельно</span>
                                </div>
                            </div>

                            <div className={`fixed bottom-0 left-0 right-0 p-4 border-t ${
                                isDark ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                            }`}>
                                <Button
                                    onClick={handleCheckout}
                                    className="w-full py-3 rounded-md"
                                    style={{
                                        backgroundColor: themeParams.button_color || '#3390EC',
                                        color: themeParams.button_text_color || '#FFFFFF'
                                    }}
                                >
                                    Продолжить
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default CartPage;