import React, { useEffect, useState } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { useLocation } from 'react-router-dom';
import { config } from '../config';

interface NetworkErrorHandlerProps {
    children: React.ReactNode;
    onRetry?: () => void;
}

const NetworkErrorHandler: React.FC<NetworkErrorHandlerProps> = ({ children, onRetry }) => {
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const { isDark } = useTheme();
    const location = useLocation();

    useEffect(() => {
        let isMounted = true;

        const handleError = (event: Event) => {
            const error = event as ErrorEvent;
            console.error('Network error caught:', error);
            
            let message = 'Не удалось подключиться к серверу. Пожалуйста, проверьте подключение к интернету.';
            
            if (error.message.includes('CORS')) {
                message = `Ошибка CORS: Не удалось подключиться к серверу ${config.apiUrl}. Проверьте настройки CORS на сервере.`;
            } else if (error.message.includes('Network Error')) {
                message = 'Сервер недоступен. Пожалуйста, проверьте подключение к интернету.';
            } else if (error.message.includes('Failed to fetch')) {
                message = 'Не удалось получить данные с сервера. Возможно, сервер временно недоступен.';
            }

            if (isMounted) {
                setIsError(true);
                setErrorMessage(message);
            }
        };

        const handleNetworkError = (event: CustomEvent) => {
            console.error('Custom network error:', event.detail);
            if (isMounted) {
                setIsError(true);
                setErrorMessage(event.detail);
            }
        };

        const handleAxiosError = (error: any) => {
            console.error('Axios error:', error);
            let message = 'Произошла ошибка при запросе к серверу.';
            
            if (error.code === 'ERR_NETWORK') {
                message = 'Сервер недоступен. Проверьте подключение к интернету.';
            } else if (error.code === 'ERR_CORS') {
                message = `Ошибка CORS: Не удалось подключиться к серверу ${config.apiUrl}. Проверьте настройки CORS на сервере.`;
            } else if (error.response) {
                message = `Ошибка сервера: ${error.response.status} ${error.response.statusText}`;
            }

            if (isMounted) {
                setIsError(true);
                setErrorMessage(message);
            }
        };

        // Проверяем доступность сервера при монтировании компонента
        const checkServerAvailability = async () => {
            try {
                const controller = new AbortController();
                const timeoutId = setTimeout(() => controller.abort(), 5000); // 5 секунд таймаут

                const response = await fetch(config.apiUrl, {
                    signal: controller.signal,
                    method: 'HEAD' // Используем HEAD запрос для проверки доступности
                });
                
                clearTimeout(timeoutId);
                
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                if (isMounted) {
                    setIsError(false);
                    setErrorMessage('');
                }
            } catch (error) {
                console.error('Server availability check failed:', error);
                if (isMounted) {
                    setIsError(true);
                    setErrorMessage('Сервер недоступен. Пожалуйста, проверьте подключение к интернету.');
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };

        checkServerAvailability();

        window.addEventListener('error', handleError);
        window.addEventListener('network-error', handleNetworkError as EventListener);
        window.addEventListener('axios-error', (e: any) => handleAxiosError(e.detail));
        
        return () => {
            isMounted = false;
            window.removeEventListener('error', handleError);
            window.removeEventListener('network-error', handleNetworkError as EventListener);
            window.removeEventListener('axios-error', (e: any) => handleAxiosError(e.detail));
        };
    }, [location.pathname]);

    const handleRetry = () => {
        setIsError(false);
        setErrorMessage('');
        setIsLoading(true);
        if (onRetry) {
            onRetry();
        } else {
            window.location.reload();
        }
    };

    if (isLoading) {
        return (
            <div className={`fixed inset-0 flex items-center justify-center ${isDark ? 'bg-gray-900' : 'bg-white'}`}>
                <div className="text-center">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
                    <p className={`mt-4 ${isDark ? 'text-gray-300' : 'text-gray-600'}`}>
                        Проверка подключения к серверу...
                    </p>
                </div>
            </div>
        );
    }

    if (isError) {
        return (
            <div className={`min-h-screen flex items-center justify-center p-4 ${isDark ? 'bg-gray-900' : 'bg-white'}`}>
                <div className={`max-w-md w-full p-6 rounded-lg shadow-lg ${isDark ? 'bg-gray-800' : 'bg-white'}`}>
                    <div className="text-center">
                        <div className="mb-4">
                            <svg className="mx-auto h-12 w-12 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                            </svg>
                        </div>
                        <h2 className={`text-xl font-semibold mb-2 ${isDark ? 'text-white' : 'text-gray-900'}`}>
                            Ошибка подключения
                        </h2>
                        <p className={`mb-4 ${isDark ? 'text-gray-300' : 'text-gray-600'}`}>
                            {errorMessage}
                        </p>
                        <button
                            onClick={handleRetry}
                            className={`px-4 py-2 rounded-md ${
                                isDark 
                                    ? 'bg-blue-600 hover:bg-blue-700 text-white' 
                                    : 'bg-blue-500 hover:bg-blue-600 text-white'
                            }`}
                        >
                            Попробовать снова
                        </button>
                        {config.isDevelopment && (
                            <div className="mt-4 text-sm text-gray-500">
                                Режим разработки: {config.apiUrl}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    return <>{children}</>;
};

export default NetworkErrorHandler; 